import React from 'react';

function Header() {
    return (
        <div className="bg-light">
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <a className="navbar-brand" href="/">Amanur Rahman</a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="nav navbar-nav ml-auto">
                            <li className="nav-item">
                                <a className="nav-link" href="https://amanur.com/#resume">Resume</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://amanur.com/#about">About</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://amanur.com/#contact">Contact</a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    );
}

export default Header;
